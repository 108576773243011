import SFAApiEndpoints from "./SFA/endpoints";

const apiList = [
  SFAApiEndpoints,
  {
    url: process.env.REACT_APP_BASE_URL_LOGISTIC,
    children: [
      {
        url: "api",
        children: [
          {
            url: "auth",
            children: [
              { url: "login", name: "loginUser", method: "POST" },
              { url: "logout", name: "logoutUser", method: "POST" },
              {
                url: "change_password/:id",
                name: "changePassword",
                method: "POST",
              },
              {
                url: "reset_password",
                children: [
                  {
                    url: "request",
                    name: "requestResetPassword",
                    method: "POST",
                  },
                  {
                    url: "list",
                    name: "getListRequestPassword",
                    method: "POST",
                  },
                  {
                    url: "approval",
                    name: "approvalResetPassword",
                    method: "POST",
                  },
                ],
              },
            ],
          },
          {
            url: "cron_executor",
            children: [
              { url: "sap_get_po_sto", name: "SAPGetPOSTO", method: "POST" },
              { url: "sap_get_do_sto", name: "SAPGetDOSTO", method: "POST" },
              { url: "sap_get_gr_sto", name: "SAPGetGRSTO", method: "POST" },
              {
                url: "sap_get_po_principal",
                name: "SAPGetPOPrincipal",
                method: "POST",
              },
              {
                url: "sap_sync_data",
                name: "SAPSyncData",
                method: "POST",
              },
            ],
          },
          {
            url: "user",
            children: [
              {
                url: "list",
                name: "getListUser",
                method: "POST",
              },
              {
                url: "create",
                name: "createUser",
                method: "POST",
              },
              {
                url: "get",
                name: "getDetailUser",
                method: "POST",
              },
              {
                url: "edit/:id",
                name: "editUser",
                method: "POST",
              },
            ],
          },

          {
            url: "menu",
            children: [
              {
                url: "list",
                name: "getListMenu",
                method: "POST",
              },
              {
                url: "list_user_all_menu",
                name: "getListMenuUser",
                method: "POST",
              },
            ],
          },
          {
            url: "extract_data",
            children: [
              {
                url: "list",
                name: "getListExtractData",
                method: "POST",
              },
              {
                url: "download",
                name: "downloadExtractData",
                method: "POST",
              },
              {
                url: "upload_sap_monitoring",
                name: "getListUploadMonitoringSAP",
                method: "POST",
              },
              {
                url: "upload_sap_monitoring/detail",
                name: "getDetailUploadMonitoringSAP",
                method: "POST",
              },
              {
                url: "detail_error",
                name: "getDetailErrorExtractData",
                method: "POST",
              },
            ],
          },
          {
            url: "master_data",
            children: [
              {
                url: "check_completeness",
                name: "getListInconsistency",
                method: "POST",
              },
              {
                url: "config_extract_data_pass",
                children: [
                  {
                    url: "list",
                    name: "getListPasswordExtract",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createPasswordExtract",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editPasswordExtract",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deletePasswordExtract",
                    method: "POST",
                  },
                ],
              },
              {
                url: "sloc",
                children: [
                  {
                    url: "get",
                    name: "getListSloc",
                    method: "POST",
                  },
                ],
              },
              {
                url: "sloc_customer_group/list",
                name: "getListSlocCustomerGroupLogistic",
                method: "POST",
              },
              {
                url: "sloc_customer_group/create",
                name: "createSlocCustomerGroupLogistic",
                method: "POST",
              },
              {
                url: "sloc_customer_group/update",
                name: "editSlocCustomerGroupLogistic",
                method: "POST",
              },
              {
                url: "sloc_branch",
                children: [
                  {
                    url: "list",
                    name: "getListSlocBranch",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editSlocBranch",
                    method: "POST",
                  },
                ],
              },
              {
                url: "config_comp",
                children: [
                  {
                    url: "list",
                    name: "getListConfigCompany",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createConfigCompany",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editConfigCompany",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusConfigCompany",
                    method: "POST",
                  },
                  {
                    url: "get",
                    name: "getDetailConfigCompany",
                    method: "POST",
                  },
                ],
              },
              {
                url: "config_def_sloc_comp",
                children: [
                  {
                    url: "list",
                    name: "getListSlocCompany",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createSlocCompany",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editSlocCompany",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusSlocCompany",
                    method: "POST",
                  },
                  {
                    url: "get",
                    name: "getDetailSlocCompany",
                    method: "POST",
                  },
                ],
              },
              {
                url: "branch",
                children: [
                  {
                    url: "list",
                    name: "getListBranchLogistic",
                    method: "POST",
                  },
                  {
                    url: "list_master",
                    name: "getListBranchMaster",
                    method: "POST",
                  },
                  {
                    url: "get",
                    name: "getDetailBranch",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editBranch",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createBranch",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusBranch",
                    method: "POST",
                  },
                  {
                    url: "list_master/export",
                    name: "exportListBranch",
                    method: "POST",
                  },
                ],
              },
              {
                url: "company",
                children: [
                  {
                    url: "list",
                    name: "getListCompanyLogistic",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListCompany",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createCompany",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editCompany",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteCompany",
                    method: "POST",
                  },
                ],
              },
              {
                url: "sales_org",
                children: [
                  {
                    url: "export",
                    name: "exportListSalesOrg",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createSalesOrg",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editSalesOrg",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteSalesOrg",
                    method: "POST",
                  },
                ],
              },
              {
                url: "sales_office",
                children: [
                  {
                    url: "export",
                    name: "exportListSalesOffice",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createSalesOffice",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editSalesOffice",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteSalesOffice",
                    method: "POST",
                  },
                ],
              },
              {
                url: "sales_group",
                children: [
                  {
                    url: "list",
                    name: "getListMasterSalesGroup",
                    method: "POST",
                  },
                  {
                    url: "export",
                    name: "exportListSalesGroup",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createSalesGroup",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editSalesGroup",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteSalesGroup",
                    method: "POST",
                  },
                ],
              },
              {
                url: "channel",
                children: [
                  {
                    url: "export",
                    name: "exportListChannel",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createChannel",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editChannel",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteChannel",
                    method: "POST",
                  },
                ],
              },
              {
                url: "customer_group",
                children: [
                  {
                    url: "export",
                    name: "exportListCustomerGroup",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createCustomerGroup",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editCustomerGroup",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteCustomerGroup",
                    method: "POST",
                  },
                ],
              },
              {
                url: "District",
                children: [
                  {
                    url: "list",
                    name: "getListMasterDistrict",
                    method: "POST",
                  },
                  {
                    url: "export",
                    name: "exportListDistrict",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createDistrict",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editDistrict",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteDistrict",
                    method: "POST",
                  },
                ],
              },
              {
                url: "price_list",
                children: [
                  {
                    url: "export",
                    name: "exportListPriceList",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createPriceList",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editPriceList",
                    method: "POST",
                  },

                  {
                    url: "delete",
                    name: "deletePriceList",
                    method: "POST",
                  },
                ],
              },
              {
                url: "country",
                children: [
                  {
                    url: "list",
                    name: "getListCountry",
                    method: "POST",
                  },

                  {
                    url: "list/export",
                    name: "exportListCountry",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createCountry",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editCountry",
                    method: "POST",
                  },

                  {
                    url: "delete",
                    name: "deleteCountry",
                    method: "POST",
                  },
                ],
              },
              {
                url: "region",
                children: [
                  {
                    url: "list",
                    name: "getListRegion",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListRegion",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createRegion",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editRegion",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteRegion",
                    method: "POST",
                  },
                ],
              },
              {
                url: "top",
                children: [
                  {
                    url: "list",
                    name: "getListTermOfPayment",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListTermOfPayment",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createTermOfPayment",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editTermOfPayment",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteTermOfPayment",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product",
                children: [
                  {
                    url: "list",
                    name: "getListProductLogistic",
                    method: "POST",
                  },
                  {
                    url: "get",
                    name: "getDetailProductLogistic",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductMaster",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createProduct",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editProduct",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusProduct",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_principal",
                children: [
                  {
                    url: "list",
                    name: "getListGroupPrincipal",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListGroupPrincipal",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createGroupPrincipal",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteGroupPrincipal",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editGroupPrincipal",
                    method: "POST",
                  },

                  {
                    url: "list",
                    name: "getListProductPrincipal",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductPrincipal",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createProductPrincipal",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editProductPrincipal",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteProductPrincipal",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_by_principal",
                children: [
                  {
                    url: "list",
                    name: "getListProductByPrincipal",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductByPrincipal",
                    method: "POST",
                  },
                  {
                    url: "add",
                    name: "addProductByPrincipal",
                    method: "POST",
                  },
                  {
                    url: "remove",
                    name: "removeProductByPrincipal",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_brand",
                children: [
                  {
                    url: "list",
                    name: "getListGroupBrand",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListGroupBrand",
                    method: "POST",
                  },

                  {
                    url: "list",
                    name: "getListProductBrand",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductBrand",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createProductBrand",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editProductBrand",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteProductBrand",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_by_brand",
                children: [
                  {
                    url: "list",
                    name: "getListProductByBrand",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductByBrand",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_category",
                children: [
                  {
                    url: "list",
                    name: "getListGroupCategory",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListGroupCategory",
                    method: "POST",
                  },

                  {
                    url: "list",
                    name: "getListProductCategory",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductCategory",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createProductCategory",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editProductCategory",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteProductCategory",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_by_category",
                children: [
                  {
                    url: "list",
                    name: "getListProductByCategory",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductByCategory",
                    method: "POST",
                  },
                ],
              },

              {
                url: "product_npl",
                children: [
                  {
                    url: "list",
                    name: "getListGroupNPL",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListGroupNPL",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createGroupNPL",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteGroupNPL",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editGroupNPL",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_by_npl",
                children: [
                  {
                    url: "list",
                    name: "getListProductByNPL",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductByNPL",
                    method: "POST",
                  },
                  {
                    url: "add",
                    name: "addProductByNPL",
                    method: "POST",
                  },
                  {
                    url: "remove",
                    name: "removeProductByNPL",
                    method: "POST",
                  },
                ],
              },

              {
                url: "product_retail_price",
                children: [
                  {
                    url: "list",
                    name: "getListGroupRetailPrice",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListGroupRetailPrice",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createGroupRetailPrice",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteGroupRetailPrice",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editGroupRetailPrice",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_by_retail_price",
                children: [
                  {
                    url: "list",
                    name: "getListProductByRetailPrice",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductByRetailPrice",
                    method: "POST",
                  },
                  {
                    url: "add",
                    name: "addProductByRetailPrice",
                    method: "POST",
                  },
                  {
                    url: "remove",
                    name: "removeProductByRetailPrice",
                    method: "POST",
                  },
                ],
              },

              {
                url: "product_gramation",
                children: [
                  {
                    url: "list",
                    name: "getListGroupGramation",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListGroupGramation",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createGroupGramation",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteGroupGramation",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editGroupGramation",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_by_gramation",
                children: [
                  {
                    url: "list",
                    name: "getListProductByGramation",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductByGramation",
                    method: "POST",
                  },
                  {
                    url: "add",
                    name: "addProductByGramation",
                    method: "POST",
                  },
                  {
                    url: "remove",
                    name: "removeProductByGramation",
                    method: "POST",
                  },
                ],
              },

              {
                url: "product_division_sales",
                children: [
                  {
                    url: "list",
                    name: "getListGroupDivisionSales",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListGroupDivisionSales",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createGroupDivisionSales",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteGroupDivisionSales",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editGroupDivisionSales",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_by_division_sales",
                children: [
                  {
                    url: "list",
                    name: "getListProductByDivisionSales",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductByDivisionSales",
                    method: "POST",
                  },
                  {
                    url: "add",
                    name: "addProductByDivisionSales",
                    method: "POST",
                  },
                  {
                    url: "remove",
                    name: "removeProductByDivisionSales",
                    method: "POST",
                  },
                ],
              },

              {
                url: "product_others",
                children: [
                  {
                    url: "list",
                    name: "getListGroupOthers",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListGroupOthers",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createGroupOthers",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteGroupOthers",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editGroupOthers",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_by_others",
                children: [
                  {
                    url: "list",
                    name: "getListProductByOthers",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductByOthers",
                    method: "POST",
                  },
                  {
                    url: "add",
                    name: "addProductByOthers",
                    method: "POST",
                  },
                  {
                    url: "remove",
                    name: "removeProductByOthers",
                    method: "POST",
                  },
                ],
              },

              {
                url: "product_branch",
                children: [
                  {
                    url: "list",
                    name: "getListProductBranch",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductBranch",
                    method: "POST",
                  },

                  {
                    url: "list",
                    name: "getListProductToBranch",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductToBranch",
                    method: "POST",
                  },

                  {
                    url: "add",
                    name: "addProductToBranch",
                    method: "POST",
                  },
                  {
                    url: "remove",
                    name: "removeProductToBranch",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_cat_brand",
                children: [
                  {
                    url: "list",
                    name: "getListProductCategoryBrand",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductCategoryBrand",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_uom",
                children: [
                  {
                    url: "list",
                    name: "getListProductUom",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductUom",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createProductUom",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editProductUom",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteProductUom",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_division",
                children: [
                  {
                    url: "list",
                    name: "getListProductDivision",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListProductDivision",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createProductDivision",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editProductDivision",
                    method: "POST",
                  },

                  {
                    url: "delete",
                    name: "deleteProductDivision",
                    method: "POST",
                  },
                ],
              },
              {
                url: "driver",
                children: [
                  {
                    url: "list",
                    name: "getListDriver",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListDriver",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editDriver",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createDriver",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "updateStatusDriver",
                    method: "POST",
                  },
                ],
              },
              {
                url: "reason_return",
                children: [
                  {
                    url: "list",
                    name: "getListReasonReturn",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_intra_channel_sloc",
                children: [
                  {
                    url: "get",
                    name: "getItemReceiver",
                    method: "POST",
                  },
                ],
              },
              {
                url: "mov_type",
                children: [
                  {
                    url: "list",
                    name: "getListMovType",
                    method: "POST",
                  },
                ],
              },
              {
                url: "vehicle",
                children: [
                  {
                    url: "list",
                    name: "getListVehicleLogistic",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListVehicle",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createVehicleLogistic",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editVehicleLogistic",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "updateStatusVehicleLogistic",
                    method: "POST",
                  },
                ],
              },
              {
                url: "vehicle_type",
                children: [
                  {
                    url: "list",
                    name: "getListVehicleType",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListVehicleType",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editVehicleType",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createVehicleType",
                    method: "POST",
                  },

                  {
                    url: "active_inactive",
                    name: "updateStatusVehicleType",
                    method: "POST",
                  },
                ],
              },
              {
                url: "customer",
                children: [
                  {
                    url: "list",
                    name: "getListCustomerLogistic",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListCustomer",
                    method: "POST",
                  },
                  {
                    url: "get",
                    name: "getDetailCustomerLogistic",
                    method: "POST",
                  },
                  {
                    url: "approval",
                    name: "approveCustomerLogistic",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createCustomerLogistic",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editCustomerLogistic",
                    method: "POST",
                  },
                  {
                    url: "update_credit_limit/:id",
                    name: "updateCreditLimitCustomer",
                    method: "POST",
                  },
                  {
                    url: "list_credit_limit_approval",
                    name: "getListApprovalCreditLimit",
                    method: "POST",
                  },
                  {
                    url: "list_credit_limit_approval/export",
                    name: "exportListApprovalCreditLimit",
                    method: "POST",
                  },
                  {
                    url: "approval_credit_limit",
                    name: "approvalUpdateCreditLimitCustomer",
                    method: "POST",
                  },
                ],
              },
              {
                url: "get-customer",
                children: [
                  {
                    url: "list",
                    name: "getListCustomerLogistic2",
                    method: "POST",
                  },
                ],
              },
              {
                url: "salesman",
                children: [
                  {
                    url: "list",
                    name: "getListSalesmanLogistic",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListSalesman",
                    method: "POST",
                  },
                  {
                    url: "get",
                    name: "getDetailSalesmanLogistic",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editSalesmanLogistic",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createSalesmanLogistic",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusSalesmanLogistic",
                    method: "POST",
                  },
                ],
              },
              {
                url: "salesman_group",
                children: [
                  {
                    url: "export",
                    name: "exportListSalesmanGroup",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createSalesmanGroup",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editSalesmanGroup",
                    method: "POST",
                  },
                ],
              },
              {
                url: "risk_class",
                children: [
                  {
                    url: "list",
                    name: "getListRiskClass",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListRiskClass",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createRiskClass",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editRiskClass",
                    method: "POST",
                  },

                  {
                    url: "delete",
                    name: "deleteRiskClass",
                    method: "POST",
                  },
                ],
              },
              {
                url: "rules",
                children: [
                  {
                    url: "list",
                    name: "getListRules",
                    method: "POST",
                  },
                ],
              },
              {
                url: "check_rule",
                children: [
                  {
                    url: "list",
                    name: "getListCheckRule",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListCheckRule",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createCheckRule",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editCheckRule",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteCheckRule",
                    method: "POST",
                  },
                ],
              },
              {
                url: "currency",
                children: [
                  {
                    url: "list",
                    name: "getListCurrency",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListCurrency",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createCurrency",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editCurrency",
                    method: "POST",
                  },

                  {
                    url: "delete",
                    name: "deleteCurrency",
                    method: "POST",
                  },
                ],
              },
              {
                url: "incoterm",
                children: [
                  {
                    url: "list",
                    name: "getListIncoterm",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListIncoterm",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createIncoterm",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editIncoterm",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteIncoterm",
                    method: "POST",
                  },
                ],
              },
              {
                url: "rules",
                children: [
                  {
                    url: "list",
                    name: "getListRules",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListRules",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createRules",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editRules",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteRules",
                    method: "POST",
                  },
                ],
              },

              {
                url: "transportation_group",
                children: [
                  {
                    url: "list",
                    name: "getListTransportationGroup",
                    method: "POST",
                  },
                  {
                    url: "export",
                    name: "exportListTransportationGroup",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createTransportationGroup",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editTransportationGroup",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusTransportationGroup",
                    method: "POST",
                  },
                ],
              },

              {
                url: "transportation/shipping_condition",
                children: [
                  {
                    url: "list",
                    name: "getListShippingCondition",
                    method: "POST",
                  },
                  {
                    url: "export",
                    name: "exportListShippingCondition",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createShippingCondition",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editShippingCondition",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusShippingCondition",
                    method: "POST",
                  },
                ],
              },

              {
                url: "mode_of_transportation",
                children: [
                  {
                    url: "list",
                    name: "getListModeOfTransportation",
                    method: "POST",
                  },
                  {
                    url: "export",
                    name: "exportListModeOfTransportation",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createModeOfTransportation",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editModeOfTransportation",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusModeOfTransportation",
                    method: "POST",
                  },
                ],
              },

              {
                url: "transportation/shipping_type",
                children: [
                  {
                    url: "list",
                    name: "getListShippingType",
                    method: "POST",
                  },
                  {
                    url: "export",
                    name: "exportListShippingType",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createShippingType",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editShippingType",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusShippingType",
                    method: "POST",
                  },
                ],
              },
              {
                url: "route",
                children: [
                  {
                    url: "list",
                    name: "getListMasterRoute",
                    method: "POST",
                  },
                  {
                    url: "export",
                    name: "exportListMasterRoute",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createMasterRoute",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editMasterRoute",
                    method: "POST",
                  },

                  {
                    url: "delete",
                    name: "deleteMasterRoute",
                    method: "POST",
                  },
                ],
              },

              {
                url: "route_determination",
                children: [
                  {
                    url: "list",
                    name: "getListMasterRouteDetermination",
                    method: "POST",
                  },
                  {
                    url: "export",
                    name: "exportListMasterRouteDetermination",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createMasterRouteDetermination",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editMasterRouteDetermination",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteMasterRouteDetermination",
                    method: "POST",
                  },
                ],
              },

              {
                url: "tzone_by_branch",
                children: [
                  {
                    url: "list",
                    name: "getListTzoneToBranch",
                    method: "POST",
                  },
                  {
                    url: "list/export",
                    name: "exportListTzoneToBranch",
                    method: "POST",
                  },
                  {
                    url: "add",
                    name: "addTzoneToBranch",
                    method: "POST",
                  },
                  {
                    url: "remove",
                    name: "removeTzoneToBranch",
                    method: "POST",
                  },
                ],
              },
              {
                url: "branch_location",
                children: [
                  {
                    url: "list",
                    name: "getListBranchLocation",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createBranchLocation",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteBranchLocation",
                    method: "POST",
                  },
                ],
              },
              {
                url: "product_intra_channel_sloc",
                children: [
                  {
                    url: "list",
                    name: "getListMappingProductIntra",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createMappingProductIntra",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editMappingProductIntra",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteMappingProductIntra",
                    method: "POST",
                  },
                ],
              },
              {
                url: "top",
                children: [
                  {
                    url: "list",
                    name: "getListTop",
                    method: "POST",
                  },
                ],
              },
              {
                url: "mop",
                children: [
                  {
                    url: "list",
                    name: "getListMop",
                    method: "POST",
                  },
                ],
              },
              {
                url: "transportation_zone",
                children: [
                  {
                    url: "export",
                    name: "exportListTransportationZone",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createTransportationZone",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editTransportationZone",
                    method: "POST",
                  },
                  {
                    url: "delete",
                    name: "deleteTransportationZone",
                    method: "POST",
                  },
                ],
              },

              {
                url: "route_determination",
                children: [
                  {
                    url: "export",
                    name: "exportListRouteDetermination",
                    method: "POST",
                  },
                ],
              },
              {
                url: "condition_type",
                children: [
                  {
                    url: "list/export",
                    name: "exportListConditionTypeMaster",
                    method: "POST",
                  },
                  {
                    url: "list",
                    name: "getListConditionTypeMaster",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createConditionTypeMaster",
                    method: "POST",
                  },
                  {
                    url: "edit",
                    name: "editConditionTypeMaster",
                    method: "POST",
                  },
                  {
                    url: "active_inactive",
                    name: "statusConditionTypeMaster",
                    method: "POST",
                  },
                ],
              },
              {
                url: "pricing",
                children: [
                  {
                    url: "list_price_group",
                    name: "getListPricingByPriceGroup",
                    method: "POST",
                  },
                  {
                    url: "list_price_group/export",
                    name: "exportListPricingByPriceGroup",
                    method: "POST",
                  },
                  {
                    url: "list_price_group_id",
                    name: "getListPriceGroupID",
                    method: "POST",
                  },
                  {
                    url: "list_price_customer",
                    name: "getListPricingByPriceCustomer",
                    method: "POST",
                  },
                  {
                    url: "list_customer",
                    name: "getListPricingCustomer",
                    method: "POST",
                  },
                  {
                    url: "customer/create",
                    name: "addPricingByPriceCustomer",
                    method: "POST",
                  },
                  {
                    url: "customer/edit",
                    name: "editPricingByPriceCustomer",
                    method: "POST",
                  },
                  {
                    url: "customer/active_inactive",
                    name: "activeInactivePricingByPriceCustomer",
                    method: "POST",
                  },

                  {
                    url: "price_group/create",
                    name: "addPricingByPriceGroup",
                    method: "POST",
                  },
                  {
                    url: "price_group/edit",
                    name: "editPricingByPriceGroup",
                    method: "POST",
                  },
                  {
                    url: "price_group/active_inactive",
                    name: "activeInactivePricingByPriceGroup",
                    method: "POST",
                  },
                  {
                    url: "list_price_customer/export",
                    name: "exportListPricingByPriceCustomer",
                    method: "POST",
                  },
                  {
                    url: "master_price_group",
                    children: [
                      {
                        url: "list",
                        name: "getListMasterPriceGroup",
                        method: "POST",
                      },
                      {
                        url: "list/export",
                        name: "exportListPriceGroup",
                        method: "POST",
                      },
                      {
                        url: "create",
                        name: "createPriceGroup",
                        method: "POST",
                      },
                      {
                        url: "edit",
                        name: "editPriceGroup",
                        method: "POST",
                      },
                      {
                        url: "active_inactive",
                        name: "activeInactivePriceGroup",
                        method: "POST",
                      },
                    ],
                  },
                ],
              },
              {
                url: "config_print_doc",
                children: [
                  {
                    url: "list",
                    name: "getListConfigPrintDoc",
                    method: "POST",
                  },
                ],
              },
              {
                url: "sap_sync_config",
                children: [
                  {
                    url: "list",
                    name: "getListScheduleSyncSAP",
                    method: "POST",
                  },
                  {
                    url: "create",
                    name: "createScheduleSyncSAP",
                    method: "POST",
                  },
                  {
                    url: "get",
                    name: "getDetailScheduleSyncSAP",
                    method: "POST",
                  },
                  {
                    url: "edit/:id",
                    name: "editScheduleSyncSAP",
                    method: "POST",
                  },
                ],
              },
            ],
          },
          {
            url: "inventory_fg",
            children: [
              {
                url: "stock_realtime",
                children: [
                  { name: "getListStockRealTime", method: "POST" },
                  {
                    url: "export",
                    name: "exportListStockRealTime",
                    method: "POST",
                  },
                ],
              },
              {
                url: "stock_realtime_compare",
                children: [
                  // { name: "getListStockRealTimeWithSap", method: "POST" },
                  {
                    url: "export",
                    name: "exportStockRealTimeWithSap",
                    method: "POST",
                  },
                ],
              },
              {
                url: "stock_on_posting_date",
                children: [
                  { name: "getListStockMutasiGudang", method: "POST" },
                  {
                    url: "export",
                    name: "exportListStockMutasiGudang",
                    method: "POST",
                  },
                ],
              },
              {
                url: "availability_overview",
                children: [
                  { name: "getListAvailabilityOverview", method: "POST" },
                  {
                    url: "export",
                    name: "exportListAvailabilityOverview",
                    method: "POST",
                  },
                ],
              },
              {
                url: "search_availability_overview",
                name: "searchListAvailabilityOverview",
                method: "POST",
              },
              {
                url: "stock_in_transit",
                children: [
                  { name: "getListStockInTransit", method: "POST" },
                  {
                    url: "export",
                    name: "exportListStockInTransit",
                    method: "POST",
                  },
                ],
              },
              {
                url: "audit_trail_stock",
                children: [
                  { name: "getListMaterialDoc", method: "POST" },
                  {
                    url: "export",
                    name: "exportListMaterialDoc",
                    method: "POST",
                  },
                ],
              },
              {
                url: "convertion_product_uom",
                name: "getConvertionProductUom",
                method: "POST",
              },
            ],
          },
          {
            url: "intra_plant",
            children: [
              {
                url: "list_po_sto",
                children: [{ name: "getListPOsto", method: "POST" }],
              },
              {
                url: "get_po_sto",
                children: [{ name: "getDetailPOsto", method: "POST" }],
              },
              {
                url: "create_po_sto",
                children: [{ name: "createPOsto", method: "POST" }],
              },
              {
                url: "edit_po_sto/:id",
                children: [{ name: "editPOsto", method: "POST" }],
              },
              {
                url: "cancel_po_sto",
                children: [{ name: "cancelPOsto", method: "POST" }],
              },
              {
                url: "release_po_sto",
                children: [{ name: "releasePOsto", method: "POST" }],
              },
              {
                url: "unrelease_po_sto",
                children: [{ name: "unreleasePOsto", method: "POST" }],
              },
              {
                url: "approve_intra_request",
                children: [{ name: "approvePOsto", method: "POST" }],
              },
              {
                url: "unapprove_po_sto",
                children: [{ name: "unapprovePOsto", method: "POST" }],
              },
              {
                url: "list_intra_request",
                children: [{ name: "getListApprovalPOsto", method: "POST" }],
              },
              {
                url: "list_do",
                children: [{ name: "getListDOsto", method: "POST" }],
              },
              {
                url: "create_do",
                children: [{ name: "createDOsto", method: "POST" }],
              },
              {
                url: "edit_do/:id",
                children: [{ name: "editDOsto", method: "POST" }],
              },
              {
                url: "get_do",
                children: [{ name: "getDetailDOsto", method: "POST" }],
              },
              {
                url: "get_data_dn",
                children: [{ name: "getDeliveryNote", method: "POST" }],
              },
              {
                url: "cancel_do",
                children: [{ name: "cancelDOsto", method: "POST" }],
              },
              {
                url: "list_gi",
                children: [{ name: "getListGIsto", method: "POST" }],
              },
              {
                url: "cancel_gi",
                children: [{ name: "cancelGIsto", method: "POST" }],
              },
              {
                url: "get_gi",
                children: [{ name: "getDetailGIsto", method: "POST" }],
              },
              {
                url: "create_gi",
                children: [{ name: "createGIsto", method: "POST" }],
              },
              {
                url: "list_gr",
                children: [{ name: "getListGRsto", method: "POST" }],
              },
              {
                url: "get_gr",
                children: [{ name: "getDetailGRsto", method: "POST" }],
              },
              {
                url: "get_data_lpb",
                children: [{ name: "getLPBGRIntraPlant", method: "POST" }],
              },
              {
                url: "cancel_gr",
                children: [{ name: "cancelGRsto", method: "POST" }],
              },
              {
                url: "create_gr",
                children: [{ name: "createGRsto", method: "POST" }],
              },
            ],
          },
          {
            url: "intra",
            children: [
              {
                url: "channel",
                children: [
                  {
                    url: "list",
                    children: [{ name: "getListIntraChannel", method: "POST" }],
                  },
                  {
                    url: "list_gi_gr",
                    children: [
                      { name: "getListGIGRIntraChannel", method: "POST" },
                    ],
                  },
                  {
                    url: "create",
                    children: [{ name: "createIntraChannel", method: "POST" }],
                  },
                  {
                    url: "get",
                    children: [
                      { name: "getDetailIntraChannel", method: "POST" },
                    ],
                  },
                  {
                    url: "get_gi_gr",
                    children: [
                      { name: "getDetailGIGRIntraChannel", method: "POST" },
                    ],
                  },
                  {
                    url: "cancel",
                    children: [{ name: "cancelIntraChannel", method: "POST" }],
                  },
                  {
                    url: "approve",
                    children: [{ name: "approveIntraChannel", method: "POST" }],
                  },
                ],
              },
              {
                url: "sloc",
                children: [
                  {
                    url: "list",
                    children: [{ name: "getListIntraSloc", method: "POST" }],
                  },
                  {
                    url: "list_gi_gr",
                    children: [
                      { name: "getListGIGRIntraSloc", method: "POST" },
                    ],
                  },
                  {
                    url: "create",
                    children: [{ name: "createIntraSloc", method: "POST" }],
                  },
                  {
                    url: "get",
                    children: [{ name: "getDetailIntraSloc", method: "POST" }],
                  },
                  {
                    url: "get_gi_gr",
                    children: [
                      { name: "getDetailGIGRIntraSloc", method: "POST" },
                    ],
                  },
                  {
                    url: "cancel",
                    children: [{ name: "cancelIntraSloc", method: "POST" }],
                  },
                  {
                    url: "approve",
                    children: [{ name: "approveIntraSloc", method: "POST" }],
                  },
                ],
              },
            ],
          },
          {
            url: "goods_receipt",
            children: [
              {
                url: "list",
                name: "getListGRPrincipal",
                method: "POST",
              },
              {
                url: "get",
                name: "getDetailGRPrincipal",
                method: "POST",
              },
              {
                url: "cancel",
                name: "cancelGRPrincipal",
                method: "POST",
              },
              {
                url: "create",
                name: "createGRPrincipal",
                method: "POST",
              },
              {
                url: "get_data_lpb",
                name: "getLPBGRPrincipal",
                method: "POST",
              },
              {
                url: "get_data_lrb",
                name: "getLRBGRPrincipal",
                method: "POST",
              },
              {
                url: "list_return",
                name: "getListGRPrincipalReturn",
                method: "POST",
              },
              {
                url: "create_return",
                name: "createGRPrincipalReturn",
                method: "POST",
              },
              {
                url: "get_return",
                name: "getDetailGRPrincipalReturn",
                method: "POST",
              },
              {
                url: "cancel_return",
                name: "cancelGRPrincipalReturn",
                method: "POST",
              },

              {
                url: "list_po",
                name: "getListPOPrincipal",
                method: "POST",
              },
              {
                url: "list_available_po",
                name: "getListAvailablePOPrincipal",
                method: "POST",
              },
              {
                url: "get_available_po",
                name: "getDetailAvailablePOPrincipal",
                method: "POST",
              },
            ],
          },
          {
            url: "stock_opname",
            children: [
              {
                url: "list",
                children: [{ name: "getListStockOpname", method: "POST" }],
              },
              {
                url: "create",
                children: [{ name: "createStockOpname", method: "POST" }],
              },
              {
                url: "edit/:id",
                children: [{ name: "editStockOpname", method: "POST" }],
              },
              {
                url: "get",
                children: [{ name: "getDetailStockOpname", method: "POST" }],
              },
              {
                url: "approve",
                children: [{ name: "approveStockOpname", method: "POST" }],
              },
            ],
          },
          {
            url: "stock_reservation",
            children: [
              {
                url: "list",
                children: [{ name: "getListStockReservation", method: "POST" }],
              },
              {
                url: "create",
                children: [{ name: "createStockReservation", method: "POST" }],
              },
              {
                url: "edit/:id",
                children: [{ name: "editStockReservation", method: "POST" }],
              },
              {
                url: "get",
                children: [
                  { name: "getDetailStockReservation", method: "POST" },
                ],
              },
              {
                url: "approve",
                children: [{ name: "approveStockReservation", method: "POST" }],
              },
              {
                url: "unapprove",
                children: [
                  { name: "unapproveStockReservation", method: "POST" },
                ],
              },
              {
                url: "cancel",
                children: [{ name: "cancelStockReservation", method: "POST" }],
              },
            ],
          },
          {
            url: "gi_disposal",
            children: [
              {
                url: "list",
                children: [{ name: "getListGIDisposal", method: "POST" }],
              },
              {
                url: "create",
                children: [{ name: "createGIDisposal", method: "POST" }],
              },
              {
                url: "get",
                children: [{ name: "getDetailGIDisposal", method: "POST" }],
              },
              {
                url: "cancel",
                children: [{ name: "cancelGIDisposal", method: "POST" }],
              },
            ],
          },
          {
            url: "stock_mutation",
            children: [
              {
                url: "create",
                children: [{ name: "createStockMutasi", method: "POST" }],
              },
              {
                url: "cancel",
                children: [{ name: "cancelStockMutasi", method: "POST" }],
              },
              {
                url: "list_swap_handling",
                children: [{ name: "getListSwapHandling", method: "POST" }],
              },
              {
                url: "get_swap_handling",
                children: [{ name: "getDetailSwapHandling", method: "POST" }],
              },
              {
                url: "list_alih_status",
                children: [{ name: "getListAlihStatus", method: "POST" }],
              },
              {
                url: "get_alih_status",
                children: [{ name: "getDetailAlihStatus", method: "POST" }],
              },
              {
                url: "gi_from_storage",
                children: [
                  {
                    url: "list",
                    children: [
                      { name: "getListGIFromStorage", method: "POST" },
                    ],
                  },
                  {
                    url: "create",
                    children: [{ name: "createGIFromStorage", method: "POST" }],
                  },
                  {
                    url: "get",
                    children: [
                      { name: "getDetailGIFromStorage", method: "POST" },
                    ],
                  },
                  {
                    url: "cancel",
                    children: [{ name: "cancelGIFromStorage", method: "POST" }],
                  },
                ],
              },
              {
                url: "place_in_storage",
                children: [
                  {
                    url: "list",
                    children: [
                      { name: "getListPlaceInStorage", method: "POST" },
                    ],
                  },
                  {
                    url: "create",
                    children: [
                      { name: "createPlaceInStorage", method: "POST" },
                    ],
                  },
                  {
                    url: "get",
                    children: [
                      { name: "getDetailPlaceInStorage", method: "POST" },
                    ],
                  },
                  {
                    url: "get_data_bpb_canvas",
                    children: [{ name: "getBPBCanvas", method: "POST" }],
                  },
                  {
                    url: "cancel",
                    children: [
                      { name: "cancelPlaceInStorage", method: "POST" },
                    ],
                  },
                ],
              },
            ],
          },
          {
            url: "swap_handling",
            children: [
              {
                url: "report",
                name: "getListReportSwapHandling",
                method: "POST",
              },
              {
                url: "report/export",
                name: "exportListReportSwapHandling",
                method: "POST",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    url: process.env.REACT_APP_BASE_URL_SALES,
    children: [
      {
        url: "sap",
        //   children: [
        //     { name: "getListStockCompare", method: "POST" },
        //     {
        //       url: "compare",
        //       name: "exportListStockCompare",
        //       method: "POST",
        //     },
        //   ],
        // },
        children: [
          {
            url: "extract",
            children: [
              {
                url: "sync",
                name: "SAPSyncDataNewEndpoint",
                method: "POST",
              },
              {
                url: "download",
                name: "downloadExtractDataNew",
                method: "GET",
              },
              {
                url: "list",
                name: "getListExtractDataNew",
                method: "POST",
              },
            ],
          },
              {
              url: "compare-stock",
              name: "getListStockCompare",
              method: "POST",
            },
        ],
      },
      //SQ & SO
      {
        url: "sales",
        children: [
          {
            url: "quotation",
            children: [
              {
                url: "",
                name: "createQuotation",
                method: "POST",
              },
              {
                url: "realtimedisc",
                name: "createPromoSimulation",
                method: "POST",
              },
              {
                url: "save/simulation_disc/",
                name: "savePromoSimulation",
                method: "POST",
              },
              {
                url: "list/simulation_disc/",
                name: "getListPromoSimulation",
                method: "POST",
              },
              {
                url: "upload/simulation",
                name: "uploadPromoSimulation",
                method: "POST",
              },
              {
                name: "publishDraftQuotation",
                url: "draft/push/:id",
                method: "PUT",
              },
              {
                name: "cancelQuotation",
                url: "",
                method: "DELETE",
              },
              {
                name: "editQuotation",
                url: ":id",
                method: "PUT",
              },
              {
                name: "getListQuotation",
                url: "list",
                method: "POST",
              },
              {
                name: "exportListQuotation",
                url: "list/download",
                method: "POST",
              },
              {
                name: "getDetailQuotation",
                url: ":id",
                method: "GET",
              },
            ],
          },
          {
            name: "saveAsDraftQuotation",
            url: "quotations/savedraft/",
            method: "POST",
          },
          {
            name: "getDocumentFlow",
            url: "docflow/quotations/:id",
            method: "GET",
          },
          {
            name: "getDocumentFlowSQ",
            url: "docflow/sq/:id",
            method: "GET",
          },
          {
            url: "order",
            children: [
              {
                name: "getListSalesOrder",
                url: "list",
                method: "POST",
              },
              {
                name: "getListApprovalSalesOrder",
                url: "approval/list",
                method: "POST",
              },

              {
                name: "getDetailSalesOrder",
                url: ":id",
                method: "GET",
              },
              {
                name: "pushSalesOrder",
                url: "push",
                method: "POST",
              },
              {
                name: "approveSalesOrder",
                url: ":id",
                method: "PUT",
              },
              {
                name: "rejectSalesOrder",
                url: "approval/reject/:id",
                method: "PUT",
              },
            ],
          },
          {
            url: "orders/",
            name: "cancelSalesOrder",
            method: "DELETE",
          },
          {
            url: "itemstatus",
            children: [
              {
                url: "list",
                name: "getListItemStatus",
                method: "POST",
              },
              {
                url: "",
                name: "createItemStatus",
                method: "POST",
              },
              {
                url: ":id",
                name: "editItemStatus",
                method: "PUT",
              },
            ],
          },
        ],
      },
      //DO
      {
        url: "delivery",
        children: [
          {
            url: "order",
            children: [
              {
                name: "getListDeliveryOrder",
                url: "list",
                method: "POST",
              },
              {
                name: "getListDeliveryOrderShipment",
                url: "shipment/list",
                method: "POST",
              },

              {
                name: "getDetailDeliveryOrder",
                url: ":id",
                method: "GET",
              },
            ],
          },
          {
            url: "orders/",
            name: "cancelDeliveryOrder",
            method: "DELETE",
          },
          {
            url: "orders/bulk",
            name: "cancelDeliveryOrderBulk",
            method: "DELETE",
          },
          {
            url: "itineraries/",
            name: "getListShipment",
            method: "POST",
          },
          {
            url: "itineraries/collection",
            name: "getListCollection",
            method: "POST",
          },
          {
            url: "itineraries/undelivered",
            name: "getListUndelivered",
            method: "POST",
          },
          {
            url: "itinerary",
            children: [
              {
                url: "",
                name: "createShipment",
                method: "POST",
              },
              {
                url: ":id",
                name: "editShipment",
                method: "PUT",
              },
              {
                name: "getDetailShipment",
                url: ":id",
                method: "GET",
              },
              {
                name: "cancelShipment",
                url: "",
                method: "DELETE",
              },
              {
                name: "retryPGI",
                url: "",
                method: "PATCH",
              },
              {
                name: "confirmUndelivered",
                url: "undelivered/confirm",
                method: "POST",
              },
              {
                name: "getDetailUndelivered",
                url: "undelivered/:id",
                method: "GET",
              },

              {
                name: "getDetailBPB",
                url: "bpb/:id",
                method: "GET",
              },
              {
                name: "getDetailBPBbulk",
                url: "bpb/bulk",
                method: "POST",
              },
              {
                name: "printBPB",
                url: "bpb/print/:id",
                method: "GET",
              },
              {
                name: "printBPBbulk",
                url: "bpb/print/bulk",
                method: "POST",
              },
              {
                name: "getDetailBSTF",
                url: "bstf/:id",
                method: "GET",
              },
              {
                name: "printBSTF",
                url: "bstf/print/:id",
                method: "GET",
              },
              {
                name: "getDetailHPH",
                url: "hph/:id",
                method: "GET",
              },
              {
                name: "printHPH",
                url: "hph/print/:id",
                method: "GET",
              },
              {
                name: "downloadShipment",
                url: "/:id",
                method: "GET",
              },
            ],
          },
        ],
      },
      //confirm-delivered-item
      {
        url: "confirm-delivery-item/:id",
        name: "getConfirmDeliveredItem",
        method: "GET",
      },
      {
        url: "confirm-delivery-item/:id",
        name: "updateConfirmDeliveredItem",
        method: "PUT",
      },
      {
        url: "confirm-delivery-item/confirm/:id",
        name: "finishConfirmDeliveredItem",
        method: "PUT",
      },
      //GI
      {
        url: "good_issue/",
        name: "createGoodsIssue",
        method: "POST",
      },
      {
        url: "good_issue/bulk/",
        name: "createBulkGoodsIssue",
        method: "POST",
      },

      //GR
      {
        url: "good_return/",
        name: "createGoodsReturn",
        method: "POST",
      },
      {
        url: "good_return/bulk",
        name: "createBulkGoodsReturn",
        method: "POST",
      },
      {
        url: "good_return/cancel/:id",
        name: "cancelGoodsReturn",
        method: "PUT",
      },

      //swap
      {
        url: "swap/",
        name: "createSwapDO",
        method: "POST",
      },
      {
        url: "swap/bulk",
        name: "createBulkSwapDO",
        method: "POST",
      },
      {
        url: "swap/cancel/:id",
        name: "cancelSwapDO",
        method: "PUT",
      },

      //Billing & Collection
      {
        url: "billings/",
        name: "getListBilling",
        method: "POST",
      },
      {
        url: "billing",
        children: [
          { url: ":id", name: "getDetailBilling", method: "GET" },
          { url: "confirm/:id", name: "getDetailCollection", method: "GET" },
          {
            url: "submit/",
            name: "createCollection",
            method: "POST",
          },
          { url: "invoice/:id", name: "getInvoiceBilling", method: "GET" },
          {
            name: "printInvoiceBillingbulk",
            url: "invoice/print/bulk",
            method: "POST",
          },
          {
            name: "printInvoiceBilling",
            url: "invoice/print/:id",
            method: "GET",
          },
          {
            name: "getDetailInvoiceBulk",
            url: "invoice/print/bulk",
            method: "POST",
          },
          {
            name: "cancelBillingById",
            url: "cancel-billing",
            method: "POST",
          },
          {
            name: "downloadTPRData",
            url: "tpr",
            method: "POST",
          }
        ],
      },
      //Master Data
      {
        url: "reason/list",
        name: "getListReasonCancelSales",
        method: "POST",
      },
      {
        url: "company/:id",
        name: "getDetailCompany",
        method: "GET",
      },
      {
        url: "company/list",
        name: "getListCompany",
        method: "POST",
      },

      {
        url: "sales/channel/list",
        name: "getListChannel",
        method: "POST",
      },

      {
        url: "sales/office/list",
        name: "getListSalesOffice",
        method: "POST",
      },
      {
        url: "sales/group/list",
        name: "getListSalesGroup",
        method: "POST",
      },
      {
        url: "sales/division/list",
        name: "getListSalesDivision",
        method: "POST",
      },
      {
        url: "sales/district/list",
        name: "getListSalesDistrict",
        method: "POST",
      },
      {
        url: "customer/:id",
        name: "getDetailCustomer",
        method: "GET",
      },

      {
        url: "customer/list",
        name: "getListCustomer",
        method: "POST",
      },

      {
        url: "customer/group/list",
        name: "getListCustomerGroup",
        method: "POST",
      },
      {
        url: "salesman/:id",
        name: "getDetailSalesman",
        method: "GET",
      },
      {
        url: "salesman/list",
        name: "getListSalesman",
        method: "POST",
      },
      {
        url: "salesman-group/list",
        name: "getListSalesmanGroup",
        method: "POST",
      },

      {
        url: "price-group/list",
        name: "getListPriceGroup",
        method: "POST",
      },
      {
        url: "price-list/list",
        name: "getListPrice",
        method: "POST",
      },
      {
        url: "transportation/route/list",
        name: "getListRoute",
        method: "POST",
      },
      {
        url: "transportation/route/determination/list",
        name: "getListRouteDetermination",
        method: "POST",
      },
      {
        url: "transportation/zone/list",
        name: "getListTransportationZone",
        method: "POST",
      },
      {
        url: "vehicle/list",
        name: "getListVehicle",
        method: "POST",
      },
      {
        url: "condition-type/list",
        name: "getListConditionType",
        method: "POST",
      },
      {
        url: "product/:id",
        name: "getDetailProduct",
        method: "GET",
      },
      {
        url: "product/bulk",
        name: "getBulkDetailProduct",
        method: "POST",
      },
      {
        url: "product/pricing/:id",
        name: "getDetailProductWithPriceGroup",
        method: "GET",
      },
      {
        url: "product/pricing/bulk",
        name: "getBulkDetailProductWithPriceGroup",
        method: "POST",
      },
      {
        url: "product/list",
        name: "getListProduct",
        method: "POST",
      },
      {
        url: "salesorg/list",
        name: "getListSalesOrg",
        method: "POST",
      },
      {
        url: "branch/list",
        name: "getListPlant",
        method: "POST",
      },
      {
        url: "doctype/list",
        name: "getListDocType",
        method: "POST",
      },

      //Config
      {
        url: "config",
        children: [
          {
            url: "tax",
            children: [
              {
                url: "list",
                name: "getListTaxRegulator",
                method: "POST",
              },
            ],
          },
          {
            url: "sloc",
            children: [
              {
                url: "plant",
                children: [
                  {
                    url: "",
                    name: "createOrderTypeToSloc",
                    method: "POST",
                  },
                  {
                    url: "list",
                    name: "getListOrderTypeToSloc",
                    method: "POST",
                  },
                  {
                    url: ":id",
                    name: "editOrderTypeToSloc",
                    method: "PUT",
                  },
                ],
              },
              {
                url: "cust-group-2",
                children: [
                  {
                    url: "list",
                    name: "getListSlocCustomerGroup",
                    method: "POST",
                  },
                ],
              },
              {
                url: "salesman",
                children: [
                  {
                    url: "",
                    name: "createSlocSalesman",
                    method: "POST",
                  },
                  {
                    url: "list",
                    name: "getListSlocSalesman",
                    method: "POST",
                  },
                  {
                    url: ":id",
                    name: "editSlocSalesman",
                    method: "PUT",
                  },
                ],
              },
            ],
          },
          {
            url: "batch",
            children: [
              {
                url: "list",
                name: "getListProductBatch",
                method: "POST",
              },
            ],
          },
          {
            url: "sales-order-dc",
            children: [
              {
                url: "list",
                name: "getListSalesOrderChannel",
                method: "POST",
              },
            ],
          },
          {
            url: "sales-order-customer",
            children: [
              {
                url: "list",
                name: "getListSalesOrderCustomer",
                method: "POST",
              },
            ],
          },
          {
            url: "sales-order-product",
            children: [
              {
                url: "list",
                name: "getListSalesOrderProduct",
                method: "POST",
              },
            ],
          },
          {
            url: "block-so-do",
            children: [
              {
                url: "",
                name: "createAutoSOToDO",
                method: "POST",
              },
              {
                url: "list",
                name: "getListAutoSOToDO",
                method: "POST",
              },
              {
                url: ":id",
                name: "editAutoSOToDO",
                method: "PUT",
              },
            ],
          },
        ],
      },

      // Start - Compare Sales and Logistic and So
      {
        url: "relay_service/cmp-sales",
        name: "getCompareSalesList",
        method: "POST",
      },
      {
        url: "relay_service/salesDetail",
        name: "getCompareSalesDetailEds",
        method: "POST",
      },
      {
        url: "relay_service/salesSapDetail",
        name: "getCompareSalesDetailSap",
        method: "POST",
      },
      {
        url: "relay_service/cmp-movement",
        name: "getCompareLogisticList",
        method: "POST",
      },
      {
        url: "relay_service/movementDetail",
        name: "getCompareLogisticDetailEds",
        method: "POST",
      },
      {
        url: "relay_service/movementSapDetail",
        name: "getCompareLogisticDetailSap",
        method: "POST",
      },
      {
        url: "relay_service/compareSoDo",
        name: "getCompareDoSoList",
        method: "POST",
      },
      // End - Compare Sales and Logistic and So
    ],
  },
  {
    url: process.env.REACT_APP_BASE_URL_NADINE_2,
    children: [
      {
        url: "v1",
        children: [
          {
            url: "promo",
            children: [
              {
                url: "all_promo_with_filter",
                name: "getListPromo",
                method: "POST",
              },
              {
                name: "getDetailPromo",
                url: "get_promo/:id",
                method: "GET",
              },
              {
                name: "createPromo",
                url: "create_promo",
                method: "POST",
              },
              {
                name: "saveDraftPromo",
                url: "save_draft_promo",
                method: "POST",
              },
              {
                name: "updatePromo",
                url: "update_promo/:id",
                method: "PUT",
              },
              {
                name: "updateStatusPromo",
                url: "update_status_promo/:id",
                method: "PUT",
              },
              {
                url: "upload_surkom",
                name: "uploadSurkom",
                method: "POST",
              },
              {
                url: "upload_customer",
                name: "uploadCustomer",
                method: "POST",
              },
              {
                url: "upload_product_combination",
                name: "uploadProduct",
                method: "POST",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default apiList;
